<template>
  <transition name="popup-fade">
    <div
      class="popup-body"
    >
      <div class="popup-content-wrap">
        <div class="popup">
          <div class="title-box">
            <div class="title-link">{{ popUpArgs.originIdeaName }}</div>
            <i class="el-icon-close" @click="doClose" />
          </div>
          <div class="title-tip">
            <div v-if="showTipFlag">
              <span>※自社算定原単位はCradle to Gateを前提にA1～A3を対象に計算しており、A4以降はグレーアウトしています</span>
            </div>
            <div v-if="isLoop && hasRelationOriginIdea">
              <span>※自社算定原単位内で循環参照が生じているため、これ以上のドリルダウン表示はできません</span>
            </div>
          </div>
          <div class="popup-body-box">
            <div ref="popUpPanel" v-loading="showLoad">
              <TreeTablePanel
                v-if="isSelfCalcOriginIdea && hasRelationOriginIdea"
                :table-data="tableData"
                :table-max-height="tableMaxHeight"
                :is-popup="isPopup"
                :is-loop="isLoop"
              />
              <PieTablePanel
                v-else-if="isSelfCalcOriginIdea && !hasRelationOriginIdea"
                :pie-data="pieData"
                :color-data="colorData"
                :process-table-data="processTableData"
                :table-max-height="tableMaxHeight"
              />
              <OtherCalcIdeaPanel
                v-else
                :pie-data="pieData"
                :color-data="colorData"
                :process-table-data="processTableData"
                :table-max-height="tableMaxHeight"
              />
            </div>
          </div>
          <div class="tab-button-box">
            <el-button class="back-button" @click="doClose"> {{ $t('messages.backName') }}</el-button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import TreeTablePanel from '@/views/browser/treeTablePanel'
import PieTablePanel from '@/views/browser/pieTablePanel'
import OtherCalcIdeaPanel from '@/views/browser/otherCalcIdeaPanel'
export default {
  name: 'SelfCalcOriginIdeaPopup',
  components: { TreeTablePanel, PieTablePanel, OtherCalcIdeaPanel },
  inject: ['doClose'],
  props: {
    popUpArgs: {
      type: Object,
      required: true
    },
    isShowLoad: Boolean
  },
  data() {
    return {
      isSelfCalcOriginIdea: false,
      hasRelationOriginIdea: false,
      tableData: [],
      pieData: [],
      colorData: [],
      showTipFlag: false,
      processTableData: [],
      showLoad: true,
      tableMaxHeight: 0,
      isPopup: true,
      isLoop: false
    }
  },
  watch: {
    popUpArgs: {
      handler(newVal) {
        this.isSelfCalcOriginIdea = newVal.isSelfCalcOriginIdea
        this.hasRelationOriginIdea = newVal.hasRelationOriginIdea
        this.showTipFlag = newVal.showTipFlag ? newVal.showTipFlag : false
        this.tableData = newVal.tableData ? newVal.tableData : []
        this.pieData = newVal.pieData ? newVal.pieData : []
        this.colorData = newVal.colorData ? newVal.colorData : []
        this.processTableData = newVal.processTableData ? newVal.processTableData : []
        this.isLoop = newVal.isLoop ? newVal.isLoop : false
      },
      deep: true,
      immediate: true
    },
    isShowLoad: {
      handler(newVal) {
        if (this.tableData && this.hasRelationOriginIdea) {
          this.showLoad = newVal
        }
        if (this.processTableData && !this.hasRelationOriginIdea) {
          this.showLoad = newVal
        }
      }
    }
  },
  mounted() {
    const height = window.getComputedStyle(this.$refs.popUpPanel).height
    this.tableMaxHeight = Math.round(parseInt(height.substring(0, height.length - 2)))
  }
}
</script>

<style lang="scss" scoped>
.popup-body {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 998;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  .popup-content-wrap {
    position: absolute;
    top: 10%;
    left: 0;
    right: 0;
    height: 75%;
    width: 80%;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 5px;
    z-index: 999;
    user-select: none;

    .popup {
      height: 100%;
      width: 100%;

      .title-box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 1rem;
        // margin-bottom: 1%;
        margin-left: 1%;
        margin-right: 1%;
        height: 10%;
        font-family: NotoSansCJKjp-Medium;
        font-size: 22px;
        color: #333333;
        letter-spacing: 0;
        text-align: center;
        font-weight: 500;

        .title-link {
          text-align: left;
          width: 70%;
          cursor: pointer;
        }

        .el-icon-close {
          cursor: pointer;
        }
      }

      .title-tip {
        word-spacing: 2px;
        // color: #333;
        color: orange;
        font-size: 12px;
        padding-right: 2%;
        // width: 100%;
        float: right;
        }

      .popup-body-box {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        height: 72%;
        width: 100%;
        padding: 1%;

        & > div {
          width: 100%;
          height: 100%;
        }

      }

      .tab-button-box {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        // opacity: 0.85;
        background: #FFFFFF;
        height: 10%;
        margin-top: 10px;

        .back-button {
          font-family: 'NotoSansCJKjp-Regular';
          font-size: 12px;
          // color: #333333;
          letter-spacing: 0;
          font-weight: 400;
          // border: 1px solid #333333;
          border-radius: 4px;
          // margin-top: 24px;
        }
      }
    }
  }
}
/* 进入和出去的动画 */
.popup-fade-enter-active {
  animation: opacity 0.3s;
}
.popup-fade-enter-active .popup-content-wrap {
  animation: scale 0.3s;
}
.popup-fade-leave-active {
  animation: outOpacity 0.2s;
}

/* 进来的动画 */
@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes scale {
  0% {
    transform: scale(0);
  }
  60% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes outOpacity {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
