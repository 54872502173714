import request from '@/utils/request'

export function getKigyouInfoAPI(kigyoucode) {
  const data = {
    kigyoucode
  }
  console.log('call getKigyouInfoAPI')
  return request({
    url: '/api/v1/export/getKigyouInfo',
    method: 'post',
    data
  })
}

export function getReportDetailAPI(seihincode, version, kigyoucode) {
  const data = {
    seihincode,
    version,
    kigyoucode
  }
  console.log('call getReportDetailAPI')
  return request({
    url: '/api/v1/report/detail',
    method: 'post',
    data
  })
}

export function saveReportDetailAPI(data) {
  console.log('call saveReportDetailAPI')
  return request({
    url: '/api/v1/report/saveDetail',
    method: 'post',
    data
  })
}

export function exportWordAPI(seihincode, version, kigyoucode) {
  const data = {
    seihincode,
    version,
    kigyoucode
  }
  console.log('call exportWordAPI')
  return request({
    url: '/api/v1/export/getWord',
    method: 'post',
    data,
    responseType: 'blob'
  })
}

export function updateReportDetailAPI(data) {
  console.log('call updateReportDetailAPI')
  return request({
    url: '/api/v1/report/updateDetail',
    method: 'post',
    data
  })
}
export function getProcessInfoAPI(seihincode, version, oriprocessFlg) {
  const data = {
    seihincode,
    version,
    oriprocessFlg
  }
  console.log('call getProcessInfoAPI')
  return request({
    url: '/api/v1/report/getProcessInfo',
    method: 'post',
    data
  })
}

export function updateExportCountAPI(kigyoucode) {
  const data = {
    kigyoucode
  }
  console.log('call updateExportCountAPI')
  return request({
    url: '/api/v1/export/updateExportCount',
    method: 'post',
    data
  })
}
