<template>
  <div class="navbar">
    <div class="breadcrumb-container">
      <div class="top-menu">
        <el-button type="primary" size="small" class="bg-size margin-right-32" @click="drawer = true">
          <span><img src="../../assets/static/icon/menu.svg"></span>
        </el-button>
        <el-drawer
          :visible.sync="drawer"
          :direction="direction"
          :modal="false"
          :with-header="false"
          size="21%"
          :modal-append-to-body="false"
        >
          <div>
            <header class="menu-header">
              <button aria-label="close drawer" type="button" class="el-drawer__close-btn margin-left-12 margin-right-18" @click="closeicon"><i class="el-dialog__close el-icon el-icon-close" /></button>
              <div class="avatar-container" trigger="click">
                <div class="avatar-wrapper" @click="toAll">
                  <img src="../../assets/static/icon/logo.svg" class="user-avatar">
                  <span class="main-name">LCA Plus </span>
                </div>
              </div>
            </header>
            <el-menu
              default-active=""
              class="el-menu-vertical-demo"
              :unique-opened="true"
              background-color="#171717"
              text-color="#fff"
              active-text-color="#ffd04b"
              @open="handleOpen"
              @close="handleClose"
            >
              <el-menu-item index="1" @click="toAll">
                <i class="img-icon margin-right-8"><img src="@/assets/static/icon/nav-01.svg"></i>
                <span slot="title">{{ $t('messages.dashboardTitle') }}</span>
              </el-menu-item>
              <el-menu-item index="2" @click="toProductList">
                <i class="img-icon margin-right-8"><img src="@/assets/static/icon/nav-02.svg"></i>
                <span slot="title">{{ $t('messages.calculationTitle') }}</span>
              </el-menu-item>
              <el-submenu index="3">
                <template slot="title">
                  <i class="img-icon margin-right-8"><img src="@/assets/static/icon/nav-03.svg"></i>
                  <span>{{ $t('messages.emissionsTitle') }}</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item index="3-1" @click="toAdd">{{ $t('messages.registerTitle') }}</el-menu-item>
                  <el-menu-item index="3-2" @click="toTempUpdate">{{ $t('messages.temporaryEditTitle') }}</el-menu-item>
                  <el-menu-item index="3-3" @click="toRealUpdate">{{ $t('messages.finalizedEditTitle') }}</el-menu-item>
                </el-menu-item-group>
              </el-submenu>
              <el-menu-item index="4" @click="toReference">
                <i class="img-icon margin-right-8"><img src="@/assets/static/icon/nav-04.svg"></i>
                <span slot="title">{{ $t('messages.lookUpTitle') }}</span>
              </el-menu-item>
              <el-menu-item index="5" @click="toSimulate">
                <div class="border-bottom">
                  <i class="img-icon margin-right-8"><img src="@/assets/static/icon/nav-05.svg"></i>
                  <span slot="title">{{ $t('messages.simulationTitle') }}</span>
                </div>
              </el-menu-item>
              <el-submenu index="9">
                <template slot="title">
                  <i class="img-icon margin-right-8"><img src="@/assets/static/icon/nav-09.svg" style="width: 13px"></i>
                  <span>{{ $t('messages.emissionsCompareTitle') }}</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item index="9-1" @click="toCompareAbstract">{{ $t('messages.overviewCompareTitle') }}</el-menu-item>
                  <el-menu-item index="9-2" @click="toCompareExplicit">{{ $t('messages.detailsCompareTitle') }}</el-menu-item>
                </el-menu-item-group>
              </el-submenu>
              <el-submenu index="6">
                <template slot="title">
                  <i class="img-icon margin-right-8"><img src="@/assets/static/icon/nav-06.svg"></i>
                  <span>{{ $t('messages.reportTitle') }}</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item index="6-1" @click="toSeihin()">{{ $t('messages.disclosureTitle') }}</el-menu-item>
                  <el-menu-item index="6-2" @click="todownstream()">{{ $t('messages.requestTitle') }}</el-menu-item>
                  <el-menu-item index="6-3" @click="toupstream()">{{ $t('messages.receivedTitle') }}</el-menu-item>
                </el-menu-item-group>
              </el-submenu>
              <el-submenu index="7">
                <template slot="title">
                  <i class="img-icon margin-right-8"><img src="@/assets/static/icon/nav-07.svg"></i>
                  <span>{{ $t('messages.manageMasterTitle') }}</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item index="7-1" @click="toProduct">{{ $t('messages.productMasterTitle') }}</el-menu-item>
                  <el-menu-item index="7-2" @click="toOriginal">{{ $t('messages.originalTitle') }}</el-menu-item>
                  <el-menu-item index="7-3" @click="toProcess">{{ $t('messages.processTitle') }}</el-menu-item>
                  <el-menu-item index="7-4" @click="toRule">{{ $t('messages.ruleTitle') }}</el-menu-item>
                </el-menu-item-group>
              </el-submenu>
              <el-submenu index="8">
                <template slot="title">
                  <i class="img-icon margin-right-8"><img width="16" src="@/assets/static/icon/nav-08.svg"></i>
                  <span>{{ $t('messages.guidelinesTitle') }}</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item index="8-1" @click="openpdf()">{{ $t('messages.caltGuidelinesTitle') }}</el-menu-item>
                  <el-menu-item index="8-2" @click="openvideo()">{{ $t('messages.videoTitle') }}</el-menu-item>
                  <el-menu-item index="8-3" @click="checklist()">{{ $t('messages.checkListTitle') }}</el-menu-item>
                  <el-menu-item index="8-4" @click="systemManual()">{{ $t('messages.uerManualTitle') }}</el-menu-item>
                  <el-menu-item index="8-4" @click="openOperationVideo()">{{ $t('messages.operateVideo') }}</el-menu-item>
                  <el-menu-item index="8-4" @click="openIdeaSimple()">{{ $t('messages.ideaSimpleData') }}</el-menu-item>
                </el-menu-item-group>
              </el-submenu>
              <el-submenu index="10">
                <template slot="title">
                  <i class="img-icon margin-right-8"><img width="16" src="@/assets/static/icon/nav-10.png"></i>
                  <span>{{ $t('messages.termsAndConditions') }}</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item index="10-1" @click="openUsePdf()">{{ $t('messages.lcaPlustermAndCon') }}</el-menu-item>
                  <el-menu-item index="10-2" @click="openIdeaPdf()">{{ $t('messages.licensetermAndCon') }}</el-menu-item>
                </el-menu-item-group>
              </el-submenu>
              <el-menu-item index="10-1" @click="openSupportPdf()"><i class="el-icon-service" style="color:white" />{{ $t('messages.calculationSupport') }}</el-menu-item>
            </el-menu>
          </div>
        </el-drawer>
      </div>
      <div class="avatar-container" trigger="click">
        <div class="avatar-wrapper" @click="toAll">
          <img src="../../assets/static/icon/logo.svg" class="user-avatar">
          <span class="main-name">LCA Plus </span>
        </div>
      </div>
    </div>

    <div class="right-menu">
      <el-dropdown class="language-container" trigger="click">
        <div>
          <span>Language: {{ language }}</span>
          <i class="el-icon-caret-bottom" />
        </div>
        <el-dropdown-menu slot="dropdown" class="languaeg-dropdown">
          <el-dropdown-item @click.native="changeLanguage(1)">
            <span style="display:block;text-align:center">日本語</span>
          </el-dropdown-item>
          <el-dropdown-item @click.native="changeLanguage(2)">
            <span style="display:block;text-align:center">English</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-dropdown class="avatar-container" trigger="click">
        <div class="avatar-wrapper">
          <img src="../../assets/static/icon/userIcon.png" class="user-avatar">
          <i class="el-icon-caret-bottom" />
          <span id="company-name" style="margin-right: 1rem;">{{ companyName }}</span>
          <span id="user-name">{{ userName }}（{{ roleName }}）</span>
        </div>
        <el-dropdown-menu slot="dropdown" class="user-dropdown">
          <el-dropdown-item v-if="false" @click.native="edituser">
            <span style="display:block;text-align:center">ユーザー情報変更</span>
          </el-dropdown-item>
          <el-dropdown-item v-if="false" @click.native="adduser">
            <span style="display:block;text-align:center">新規ユーザー</span>
          </el-dropdown-item>
          <el-dropdown-item @click.native="logout">
            <span style="display:block;text-align:center">{{ $t('messages.logout') }}</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import './left-nav.scss'
import { mapGetters } from 'vuex'
import Breadcrumb from '@/components/Breadcrumb'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Breadcrumb
  },
  data() {
    return {
      userName: JSON.parse(this.$store.state.user).fullName,
      companyName: JSON.parse(this.$store.state.user).kigyouName,
      roleName: JSON.parse(this.$store.state.role).userRole === 'Member' ? '一般' : '管理者',
      drawer: false,
      navmenu: false,
      direction: 'ltr',
      language: '日本語'
    }
  },
  computed: {
    ...mapGetters([
      // 'sidebar',
      'avatar'
    ])
  },
  methods: {
    toAll() {
      this.$router.push({ path: '/pf/browser/dashboard' })
      this.drawer = false
      this.navmenu = false
    },
    toProductList() {
      if (this.$route.path === '/pf/browser/productList') {
        this.$router.replace(
          { name: '/refresh'
          })
      } else {
        this.$router.push({ path: '/pf/browser/productList' })
      }
      this.drawer = false
      this.navmenu = false
    },
    toReference() {
      this.$router.push(
        // { name: '/browser/single',
        { name: '/browser/referenceIndex',
          params: {
            fromPage: 'navbar'
          }
        })
      this.drawer = false
      this.navmenu = false
    },
    toSimulate() {
      this.$router.push(
        { name: '/input/simulate',
          params: {
            fromPage: 'navbar'
          }
        })
      this.drawer = false
      this.navmenu = false
    },
    toAdd() {
      this.$router.push({ path: '/pf/input/add' })
      this.drawer = false
      this.navmenu = false
    },
    toTempUpdate() {
      if (this.$route.path === '/pf/browser/productList') {
        this.$router.replace(
          { name: '/refresh',
            params: {
              fromPage: 'navPage',
              filterMethod: '一時保存'
            }
          })
      } else {
        this.$router.push(
          { name: '/browser/productList',
            params: {
              fromPage: 'navPage',
              filterMethod: '一時保存'
            }
          })
      }
      this.drawer = false
      this.navmenu = false
    },
    toRealUpdate() {
      if (this.$route.path === '/pf/browser/productList') {
        this.$router.replace(
          { name: '/refresh',
            params: {
              fromPage: 'navPage',
              filterMethod: '確定保存'
            }
          })
      } else {
        this.$router.push(
          { name: '/browser/productList',
            params: {
              fromPage: 'navPage',
              filterMethod: '確定保存'
            }
          })
      }
      this.drawer = false
      this.navmenu = false
    },
    toSeihin() {
      this.$router.push(
        { name: '/operate/seihin',
          params: {
            fromPage: 'navbar'
          }
        })
      this.drawer = false
      this.navmenu = false
    },
    todownstream() {
      this.$router.push(
        { name: '/requestManage/downstream',
        })
      this.drawer = false
      this.navmenu = false
    },
    toupstream() {
      this.$router.push(
        { name: '/requestManage/upstream',
          // params: {
          //   fromPage: 'navbar'
          // }
        })
      this.drawer = false
      this.navmenu = false
    },
    toOriginal() {
      this.$router.push({ path: '/pf/idea/original' })
      this.drawer = false
      this.navmenu = false
    },
    toProduct() {
      this.$router.push({ path: '/pf/productMaster/product' })
      this.drawer = false
      this.navmenu = false
    },
    toProcess() {
      this.$router.push({ path: '/pf/processFormat/process' })
      this.drawer = false
      this.navmenu = false
    },
    toRule() {
      this.$router.push({ path: '/pf/ideaRule/basicRule' })
      this.drawer = false
      this.navmenu = false
    },
    openpdf() {
      if (this.$i18n.locale === 'jp') {
        const routeUrl = this.$router.resolve({
          path: '/static/pdfjs/web/viewer.html?file=/static/Guideline-JP.pdf'
        })
        window.open(routeUrl.href, '_blank')
      } else if (this.$i18n.locale === 'en') {
        const routeUrl = this.$router.resolve({
          path: '/static/pdfjs/web/viewer.html?file=/static/Guideline-EN.pdf'
        })
        window.open(routeUrl.href, '_blank')
      }
    },
    openvideo() {
      window.open('https://www.youtube.com/embed/aFbUNkmvKqc?rel=0', '_blank')
    },
    openOperationVideo() {
      window.open('https://www.youtube.com/playlist?list=PLl8aIiSEdhzQqZva9gm9CE8VTOPhW8weF', '_blank')
    },
    openIdeaSimple() {
      let routeUrl = ''
      if (this.$i18n.locale === 'jp') {
        routeUrl = this.$router.resolve({
          path: '/static/IDEA v.2.3 原単位名一覧表.xlsx'
        })
      } else {
        routeUrl = this.$router.resolve({
          path: '/static/IDEA v.2.3 原単位名一覧表.xlsx'
        })
      }
      window.location.href = routeUrl.href
    },
    checklist() {
      if (this.$i18n.locale === 'jp') {
        const routeUrl = this.$router.resolve({
          path: '/static/pdfjs/web/viewer.html?file=/static/checklist-JP.pdf'
        })
        window.open(routeUrl.href, '_blank')
      } else if (this.$i18n.locale === 'en') {
        const routeUrl = this.$router.resolve({
          path: '/static/pdfjs/web/viewer.html?file=/static/checklist-EN.pdf'
        })
        window.open(routeUrl.href, '_blank')
      }
    },
    systemManual() {
      if (this.$i18n.locale === 'jp') {
        const routeUrl = this.$router.resolve({
          path: '/static/pdfjs/web/viewer.html?file=/static/system-JP.pdf'
        })
        window.open(routeUrl.href, '_blank')
      } else if (this.$i18n.locale === 'en') {
        const routeUrl = this.$router.resolve({
          path: '/static/pdfjs/web/viewer.html?file=/static/system-EN.pdf'
        })
        window.open(routeUrl.href, '_blank')
      }
    },
    openUsePdf() {
      const routeUrl = this.$router.resolve({
        path: '/static/pdfjs/web/viewer.html?file=/static/LCA Plus利用規約v1.1.1.pdf'
      })
      window.open(routeUrl.href, '_blank')
    },
    openSupportPdf() {
      const routeUrl = this.$router.resolve({
        // LCA Plus利用規約 PDFファイルパス
        path: '/static/pdfjs/web/viewer.html?file=/static/システム上 SC 紹介画面.pdf'
      })
      window.open(routeUrl.href, '_blank')
    },
    openIdeaPdf() {
      const routeUrl = this.$router.resolve({
        path: '/static/pdfjs/web/viewer.html?file=/static/IDEAライセンス 規約 SuMPO版.pdf'
      })
      window.open(routeUrl.href, '_blank')
    },
    // toAll() {
    //   this.$router.push({ path: '/pf/browser/all' })
    // },
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
    },
    async logout() {
      // await this.$store.dispatch('user/logout')
      // this.$router.push({ path: '/index' })
      // alert('logout!')
      this.$store.commit('DELETE_SEIHIN')
      this.$store.commit('DELETE_USER')
      this.$store.commit('DELETE_RETURN_LOGIN')
      const routeUrl = this.$router.resolve({
        path: '/index'
        // query: {id:1}
      })
      window.open(routeUrl.href, '_self')
    },
    changeLanguage(value) {
      if (value === 1) {
        this.language = '日本語'
        this.roleName = JSON.parse(this.$store.state.role).userRole === 'Member' ? '一般' : '管理者'
        this.$i18n.locale = 'jp'
        // localStorage.setItem('lang','jp')
      } else {
        this.language = 'English'
        this.roleName = JSON.parse(this.$store.state.role).userRole === 'Member' ? 'General' : 'Administrator'
        this.$i18n.locale = 'en'
        // localStorage.setItem('lang','en')
      }
    },

    edituser() {
      this.$router.push(
        {
          name: '/user/edit',
          params: {
            userid: 'IBM001',
            userauth: 'M'
          }
        }
      )
    },
    adduser() {
      this.$router.push(
        {
          name: '/user/add',
          params: {
            userid: 'IBM001',
            userauth: 'M'
          }
        }
      )
    },
    toCompareAbstract() {
      this.$router.push({ path: '/pf/compare/abstract' })
      this.drawer = false
      this.navmenu = false
    },
    toCompareExplicit() {
      this.$router.push({ path: '/pf/compare/explicit' })
      this.drawer = false
      this.navmenu = false
    },
    handleOpen(key, keyPath) {
      // console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      // console.log(key, keyPath);
    },
    closeicon() {
      this.drawer = false
    }
  }
}
</script>

<style lang="scss" scoped>
.main-name {
  font-size: 2rem;
  font-weight: 600;
  margin-left: 1rem;
  color: #fff;
  cursor: pointer;
}
.navbar {
  height: 5.2rem;
  width: 100%;
  background: #171717;
  box-shadow: 0 1px 0.4rem rgba(0,21,41,.08);
  display: flex;
  justify-content: space-between;
}
  .hamburger-container {
    line-height: 4.6rem;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background .3s;
    -webkit-tap-highlight-color:transparent;

    &:hover {
      background: rgba(0, 0, 0, .025)
    }
  }

  .breadcrumb-container {
    display: flex;
    align-items: center;
    justify-content: center;
    // float: left;
    height: 100%;
    margin-left: 2.4rem;
  }

  .right-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    // float: right;
    height: 100%;
    margin-right: 2.4rem;
    // line-height: 50px;
  }

    .right-menu-item {
      display: inline-block;
      padding: 0 0.8rem;
      height: 100%;
      font-size: 1.6rem;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background .3s;

        &:hover {
          background: rgba(0, 0, 0, .025)
        }
      }
    }

    .avatar-container {
      // margin-right: 60px;
      cursor: pointer;
      .avatar-wrapper {
        // margin-top: 5px;
        display: flex;
        align-items: center;
        text-align: center;
        position: relative;

        .user-avatar {
          margin-right: 0.5rem;
          cursor: pointer;
          // width: 30px;
          height: 2.4rem;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -1.5rem;
          display: flex;
          align-items: center;
          font-size: 1.2rem;
        }
      }
    }
    .el-dropdown-menu {
      // left:auto!important;
      // right:20px;
      width: 180px!important;
    }
    // .languaeg-dropdown {
    //   left: auto !important;
    //   right: 30.5rem;
    //   width: 10rem !important;
    // }

    .language-container {
      right: 3rem;
      cursor: pointer;
    }
    .el-popper {
      min-width: 180px!important;
    }
</style>
