<template>
  <span class="tree-table-body">
    <table style="width:100%;" border="0" cellpadding="0" cellspacing="0" :class="{'table-scroll':levelNum===0}">
      <tr style="background-color: #e8f1f9; height: 4rem">
        <td style="background-color: white !important;width: 2%;padding-right: 0rem">
          <input type="checkbox" class="el-checkbox" :value="this.checkSubassyId" @change="checked=>checkCallChange(checked,row, this.checkSubassyId)">
        </td>
        <td style="width: 30%" @click="getData">
          <div :style="getPaddingStyle" style="overflow-x:auto">
            <i v-if="(row.detail && row.detail.length>0) ||(row.child && row.child.length > 0)" :class="{'el-icon-arrow-down': isShow, 'el-icon-arrow-right': !isShow}" />
            <i style="padding-right:10px" />
            {{ row.itemName }}
          </div>
        </td>
        <td style="width: 19%" />
        <td style="width: 20%" />
        <td style="width: 6%; text-align: center;" />
        <td style="width: 5%" />
        <td style="width: 7%">{{ toScientificNum(row.sum)[0] }} {{ toScientificNum(row.sum)[1] }} <sup>{{ toScientificNum(row.sum)[2] }}</sup></td>
        <td v-if="row.sumPre !== '-'" style="width: 5%; text-align: center;">{{ (Number(row.sumPre) * 100).toFixed(2) }}%</td>
        <td v-else style="width: 5%; text-align: center;">-</td>
        <td style="width: 7%" />
      </tr>
      <template v-if="isShow && row.detail && row.detail.length >0">
        <tr v-for="(i, index) in row.detail" :key="index">
          <td style="background-color: white !important;width: 1%; padding-right: 0rem" />
          <td>
            <div :style="detailStyle" style="overflow-x:auto">
              <i style="padding-right:24px" />
              {{ i.itemName }}
            </div>
          </td>
          <td>{{ i.processName }}</td>
          <td>{{ formatIdeaName(i.ideaName) }}</td>
          <td style="text-align: center;">{{ i.quantity }}</td>
          <td>{{ i.unit }}</td>
          <td>{{ toScientificNum(i.emissionc)[0] }} {{ toScientificNum(i.emissionc)[1] }} <sup>{{ toScientificNum(i.emissionc)[2] }}</sup></td>
          <td v-if="i.pre !== '-'" style="text-align: center;">{{ (Number(i.pre) * 100).toFixed(2) }}%</td>
          <td v-else style="text-align: center;">-</td>
          <td v-if="i.budomari" style="text-align: center;">{{ i.budomari }}%</td>
          <td v-else />
        </tr>
      </template>

    </table>
    <span v-show="isShow && row.child && row.child.length >0">
      <callTree v-for="(i, index) in row.child" :key="index" :show-all="showAll" :row="i" :level-num="levelNum + 1" :checkSubassyId="i.detail[0].subassyId" />
    </span>
  </span>
</template>

<script>
import { EventBus } from '@/main'
import $ from 'jquery'
import { debug } from 'console'
import { times, divide } from '../../utils/number'
export default {
  name: 'callTree',
  props: {
    row: {
      type: Object,
      default() {
        return {
          itemName: '',
          detail: [],
          child: []
        }
      }
    },
    levelNum: {
      type: Number,
      default: 0
    },
    showAll: {
      type: Boolean,
      default: false
    },
    checkSubassyId: {
      type: String,
      default: ''
    }
  },
  data: function() {
    return {
      isShow: false,
      callNum: 0,
      callEmisSum: 0,
      checkRadio: 0,
      subassyIdArr: [{
        subassyId: 0
      }],
      itemIndex: 0,
      checkFlag: false,
      indexId: 0,
      backTopSubassyId: '',
      change: false
    }
  },
  computed: {
    getPaddingStyle() {
      return `padding-left: ${this.levelNum * 3}rem`
    },
    detailStyle() {
      const width = (this.levelNum + 1) * 3
      return `padding-left: ${width}rem; max-width: ${width > 40 ? width + 10 : 50}rem`
    }
  },
  watch: {
    showAll(newVal) {
      this.isShow = newVal
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.backTopSubassyId = this.$store.state.subId
      if (this.backTopSubassyId && this.backTopSubassyId != '') {
        $('.el-checkbox').find('input:checkbox')
        let checkArr = $('.el-checkbox')
        for (var l = 0; l < checkArr.length; l++) {
          if (this.backTopSubassyId === checkArr[l]._value) {
            checkArr[l].checked = true
          } else {
            checkArr[l].checked = false
          }
          checkArr[l].disabled = true
        }
      }
    })
  },
  updated() {
    this.change = this.$store.state.change
    if (this.change) {
      $('.el-checkbox').find('input:checkbox')
      let checkArr = $('.el-checkbox')
      for (var l = 0; l < checkArr.length; l++) {
        checkArr[l].checked = false
      }
      this.$store.commit('CHANGE_SEHIN', false)
    }
  },
  methods: {
    getData() {
      this.isShow = !this.isShow
    },
    toScientificNum(num) {
      num = Number(num)
      if (!num) {
        num = 0
      }
      var eformat = num.toExponential()
      var tmpArray = eformat.match(/(.?\d\.?\d*)e(.?\d*)/)
      tmpArray[1] = Math.round(times(tmpArray[1], 100)) / 100
      tmpArray[1] = parseFloat(tmpArray[1]).toFixed(2)
      tmpArray[2] = tmpArray[2].replace('+', '')
      var ScientificNum
      if (tmpArray[2] !== '0') {
        ScientificNum = [tmpArray[1], 'x10', tmpArray[2]]
      } else {
        ScientificNum = [tmpArray[1]]
      }
      return ScientificNum
    },
    formatIdeaName(idea) {
      let formattedIdea = ''
      if (idea.indexOf('a3b523ac16674572848091098e') > -1) {
        formattedIdea = idea.replace('a3b523ac16674572848091098e', '')
      } else {
        formattedIdea = idea
      }
      return formattedIdea
    },
    checkCallChange(checked, row, subassyId) {
      $('.el-checkbox').find('input:checkbox')
      let checkArr = $('.el-checkbox')
      for (var l = 0; l < checkArr.length; l++) {
        if (subassyId === checkArr[l]._value && checked) {
          checkArr[l].checked = true
        } else {
          checkArr[l].checked = false
        }
      }
      this.itemIndex = 0
      let oneDetailLength = row.detail.length
      for (var i = 0; i < row.detail.length; i++) {
        let subassyId = { subassyId: '' }
        subassyId.subassyId = row.detail[i].subassyId
        this.subassyIdArr[this.itemIndex] = subassyId
        this.itemIndex++
      }
      if (row.child) {
        this.getCallDataList(row.child)
      }
      this.callNum = this.itemIndex
      this.callEmisSum = row.sum
      let topItemName = row.itemName
      EventBus.$emit('toCallNumAdd', this.callNum)
      EventBus.$emit('toCallEmisSumAdd', this.callEmisSum)
      EventBus.$emit('toSubIdArrAdd', this.subassyIdArr)
      EventBus.$emit('toOneLengthCallPop', oneDetailLength)
      EventBus.$emit('toCallPop', topItemName)
    },
    getCallDataList(childArr) {
      for (var i = 0; i < childArr.length; i++) {
        for (var j = 0; j < childArr[i].detail.length; j++) {
          var tempSubassyId = { subassyId: childArr[i].detail[j].subassyId }
          this.subassyIdArr.splice(this.itemIndex, 0, tempSubassyId)
          this.itemIndex++
        }
        if (childArr[i].child) {
          this.getCallDataList(childArr[i].child)
        }
      }
    },
    backCallChange(backTopSubassyId) {
      this.$nextTick(() => {
        $('.el-checkbox').find('input:checkbox')
        let checkArr = $('.el-checkbox')
        for (var l = 0; l < checkArr.length; l++) {
          if (backTopSubassyId === checkArr[l]._value) {
            checkArr[l].checked = true
          } else {
            checkArr[l].checked = false
          }
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.tree-table-body {
    // position: absolute;
    // top: 0;
    table {
      overflow-x: scroll;
    }
    width: 100%;
    tr {
        height: 4rem;
        width: 100rem;
      td {
        padding-right: 2.4rem;
        white-space: nowrap;
      }
    }
}
</style>
