import Vue from 'vue'

import 'normalize.css/normalize.css' // A modern alternative to CSS resets

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// import locale from 'element-ui/lib/locale/lang/ja' // lang i18n
import lang from 'element-ui/lib/locale/lang/ja'
import locale from 'element-ui/lib/locale'

import '@/styles/index.scss' // global css

import App from './App'
import store from './store'
import router from './router'

// import VueAwesomeSwiper from './views/lp/node_modules/vue-awesome-swiper'
// import AOS from './views/lp/node_modules/aos'
// import VueScrollTo from './views/lp/node_modules/vue-scrollto'
// import './views/lp/node_modules/scroll-hint/css/scroll-hint.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import AOS from 'aos'
import VueScrollTo from 'vue-scrollto'
import 'scroll-hint/css/scroll-hint.css'

import '@/assets/static/font/font.css'
import '@/icons' // icon
import '@/permission' // permission control
import VueYoutube from 'vue-youtube'
import i18n from './assets/language/index'
import Schema from 'async-validator'
import ElTableInfiniteScroll from 'el-table-infinite-scroll'

Schema.messages.required = fieldName => {
  if (fieldName == 'itemname') {
    return '項目名を入力してください'
  } else if (fieldName == 'displayname') {
    return '原単位名を入力してください'
  } else if (fieldName == 'quantity') {
    return '活動量を入力してください'
  } else {
    return '%s is required'
  }
}

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online ! ! !
 */
// if (process.env.NODE_ENV === 'production') {
//   const { mockXHR } = require('../mock')
//   mockXHR()
// }

// configure language
locale.use(lang)

// create public Vue Object to delivery data
export const EventBus = new Vue()

// set ElementUI lang to EN
Vue.use(ElementUI, { lang })
// 如果想要中文版 element-ui，按如下方式声明
// Vue.use(ElementUI)
Vue.use(VueAwesomeSwiper)
Vue.use(AOS.init())
Vue.use(VueYoutube)
Vue.use(ElTableInfiniteScroll)
Vue.use(VueScrollTo, {
  duration: 600,
})

Vue.config.productionTip = false

new Vue({
  el: '#app',
  i18n,
  router,
  store,
  render: h => h(App)
})
