<template>
  <span class="tree-table-body">
    <table
      style="width:100%;"
      border="0"
      cellpadding="0"
      cellspacing="0"
      :class="{'table-scroll':levelNum===0}"
    >
      <tr style="background-color: #e8f1f9; height: 2rem">
        <td style="width: 100%; display: flex;" @click="getData">
          <div :style="getPaddingStyle" style="background: white;" />
          <div style="overflow-x:auto;display: flex;">
            <i v-if="(row.detail && row.detail.length>0) ||(row.child && row.child.length > 0)" style="padding-top: 0.5rem;" :class="{'el-icon-arrow-down': isShow, 'el-icon-arrow-right': !isShow}" />
            <i style="padding-right:1rem" />
            <el-tooltip class="item" effect="dark" :content="row.itemName" placement="top-start">
              <span class="colum-width-tree" style="vertical-align: bottom;">
                {{ row.itemName }}
              </span>
            </el-tooltip>
          </div>
        </td>
        <td style="width: 20%" />
        <td style="width: 20%; text-align: center;" />
        <td style="width: 6%; text-align: center;" />
        <td style="width: 5%; text-align: center;" />
        <td style="width: 7%; text-align: center;">{{ toScientificNum(row.sum)[0] }} {{ toScientificNum(row.sum)[1] }} <sup>{{ toScientificNum(row.sum)[2] }}</sup></td>
        <td v-if="row.sumPre !== '-'" style="width: 6%; text-align: center">{{ (Number(row.sumPre) * 100).toFixed(2) }}%</td>
        <td v-else style="width: 6%; text-align: center">-</td>
        <td style="width: 6%" />
      </tr>
      <template v-if="isShow && row.detail && row.detail.length >0">
        <tr v-for="(i, index) in row.detail" :key="index">
          <td>
            <div :class="i.isGray ? 'backgroub-gray-class':'backgroub-class'" :style="detailStyle" style="overflow-x:auto; display: flex;" class="nosubassytr">
              <div v-if="row.detail[index].subassyFromId" class="subassytr" />
              <i style="padding-right:0.4rem" />
              <el-tooltip class="item" effect="dark" :content="i.itemName" placement="top-start">
                <span class="colum-width-tree" style="vertical-align: bottom;">
                  {{ i.itemName }}
                </span>
              </el-tooltip>
            </div>
          </td>
          <td :class="i.isGray ? 'backgroub-gray-class':'backgroub-class'">{{ i.processName }}</td>
          <td :class="i.isGray ? 'backgroub-gray-class':'backgroub-class'">
            <div v-if="(i.isSelfCalcOriginIdea === true && i.auth === 0) || (i.isSelfCalcOriginIdea === false && i.auth > 1)" :class="isLoop ? 'origin-idea-loop-style':'origin-idea-style'" @click="showDetail(i)">
              <div v-if="isPopup">
                <el-tooltip v-if="formatIdeaName(i.ideaName).length > 24" class="item" effect="dark" :content="formatIdeaName(i.ideaName)" placement="top-start">
                  <span>{{ formatIdeaName(i.ideaName).slice(0,19) }}...</span>
                </el-tooltip>
                <span v-else>{{ formatIdeaName(i.ideaName) }}</span>
              </div>
              <div v-else>
                <span>{{ formatIdeaName(i.ideaName) }}</span>
              </div>
            </div>
            <div v-else>
              <div v-if="isPopup">
                <el-tooltip v-if="formatIdeaName(i.ideaName).length > 24" class="item" effect="dark" :content="formatIdeaName(i.ideaName)" placement="top-start">
                  <span>{{ formatIdeaName(i.ideaName).slice(0,19) }}...</span>
                </el-tooltip>
                <span v-else>{{ formatIdeaName(i.ideaName) }}</span>
              </div>
              <div v-else>
                <span>{{ formatIdeaName(i.ideaName) }}</span>
              </div>
            </div>
          </td>
          <td :class="i.isGray ? 'backgroub-gray-class':'backgroub-class'" style="text-align: center;">{{ formatQuantity(i.quantity) }}</td>
          <td :class="i.isGray ? 'backgroub-gray-class':'backgroub-class'" style="text-align: center;">{{ i.unit }}</td>
          <td :class="i.isGray ? 'backgroub-gray-class':'backgroub-class'" style="text-align: center;">{{ toScientificNum(i.emissionc)[0] }} {{ toScientificNum(i.emissionc)[1] }} <sup>{{ toScientificNum(i.emissionc)[2] }}</sup></td>
          <td v-if="i.pre !== '-'" :class="i.isGray ? 'backgroub-gray-class':'backgroub-class'" style="text-align: center">{{ (Number(i.pre) * 100).toFixed(2) }}%</td>
          <td v-else :class="i.isGray ? 'backgroub-gray-class':'backgroub-class'" style="text-align: center">-</td>
          <td v-if="i.budomari" :class="i.isGray ? 'backgroub-gray-class':'backgroub-class'" style="text-align: center;">{{ i.budomari }}%</td>
          <td v-else :class="i.isGray ? 'backgroub-gray-class':'backgroub-class'" />
        </tr>
      </template>

    </table>
    <span v-show="isShow && row.child && row.child.length >0">
      <tree v-for="(i, index) in row.child" :key="index" :show-all="showAll" :row="i" :level-num="levelNum + 1" :is-popup="isPopup" :is-loop="isLoop" />
    </span>
  </span>
</template>

<script>
import { formatQuantity } from '../../utils/index'
import { checkExistAPI } from '@/api/input'

export default {
  name: 'Tree',
  inject: ['getOriginIdeaInfo', 'toScientificNum', 'formatIdeaName'],
  props: {
    row: {
      type: Object,
      default() {
        return {
          itemName: '',
          detail: [],
          child: []
        }
      }
    },
    levelNum: {
      type: Number,
      default: 0
    },
    showAll: {
      type: Boolean,
      default: false
    },
    isPopup: {
      type: Boolean,
      default: false
    },
    isLoop: {
      type: Boolean,
      default: false
    },
  },
  data: function() {
    return {
      isShow: false
    }
  },
  computed: {
    getPaddingStyle() {
      let width = 0
      if (this.levelNum <= 1) {
        width = this.levelNum * 3 - 1.7
      } else {
        width = this.levelNum * 3 - 1.9
      }
      return `width: ${width}rem`
    },
    detailStyle() {
      // const width = (this.levelNum + 1) * 3 - 1
      let width = 0
      if (this.levelNum === 0) {
        width = 2.6
      } else {
        width = (this.levelNum + 1) * 3 - 0.9
      }
      return `margin-left: ${width}rem; max-width: ${width > 40 ? width + 10 : 50}rem`
    }
  },
  watch: {
    showAll(newVal) {
      this.isShow = newVal
    }
  },
  methods: {
    formatQuantity,
    getData() {
      this.isShow = !this.isShow
    },
    rejectMsg() {
      this.$message.error('ネットワークエラーが発生した。')
    },
    showDetail(detail) {
      if (this.isLoop) {
        return
      }
      const { ideaName, seihinCode, ideaVersion, quantity, unit, auth, budomari, isSelfCalcOriginIdea } = detail
      // 製品バージョン存在チェック
      checkExistAPI(seihinCode, ideaVersion)
        .then(res => {
          if (res.code === 200) {
            if (!res.data.tempFinalFlag && res.data.deleteFinalFlag) {
              this.$message({
                message: 'この製品バージョンは削除されました。',
                type: 'warning'
              })
            } else {
              let ideaCode
              if (isSelfCalcOriginIdea) {
                ideaCode = `s_${seihinCode}`
              } else {
                ideaCode = `v_${seihinCode}`
              }
              const originIdeaInfo = {
                isShowPopup: true,
                isSelfCalcOriginIdea,
                originIdeaName: this.formatIdeaName(ideaName),
                seihinCode,
                ideaCode,
                ideaVersion,
                quantity,
                unit,
                auth,
                budomari
              }
              this.getOriginIdeaInfo(originIdeaInfo)
            }
          } else {
            this.rejectMsg()
          }
        }).catch((error) => {
          console.log('error', error)
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.tree-table-body {
    // position: absolute;
    // top: 0;
    user-select: text;
    table {
      overflow-x: scroll;
    }
    width: 100%;
    tr {
        height: 2rem;
        width: 100rem;
      td {
        // padding-right: 2.4rem;
        white-space: nowrap;
      }
    }
}

.subassytr{
  border-left: 5px solid #f7e7cb;
}

.nosubassytr{
  border-left: 5px solid #b8e3f1;
}

.colum-width-tree {
    max-width: 20rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.origin-idea-style {
  font-size: 1.2rem;
  color: #0087CF;
  letter-spacing: 0;
  font-weight: 400;
  cursor: pointer;
  text-decoration: underline;
}

.origin-idea-loop-style {
  font-size: 1.2rem;
  color: #0087CF;
  letter-spacing: 0;
  font-weight: 400;
  text-decoration: underline;
}

.backgroub-gray-class {
  background-color: #C5C5C5;
}

</style>
