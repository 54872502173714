<template>
  <div class="float-container">
    <!--add dashboard nav-->
    <div v-if="dashboard" class="title">
      <span class="input-span">
        <strong>{{ $t('messages.dashboardTitle') }}</strong>
      </span>
      <el-button
        type="primary"
        size="small"
        @click="toAll"
      >{{ $t('messages.productList') }}</el-button>
    </div>
    <div v-if="all" class="title">
      <span class="input-span">
        <strong
          class="titleFont"
          @click="toDashboard"
        >
          <i style="padding-right: 6px" class="el-icon-arrow-left" />
          {{ $t('messages.productListPage') }}</strong>
      </span>
    </div>
    <div v-if="sansyo" class="title">
      <span class="input-span">
        <strong
          class="titleFont"
          @click="toBack"
        ><i style="padding-right: 6px" class="el-icon-arrow-left" />{{ $t('messages.prd') }}</strong>
      </span>
      <!-- <el-row>
        <el-button
          :loading="loading4Single"
          type="primary"
          class="seihin-button"
          @click="toNew()"
        >新製品を追加する&nbsp;＋</el-button>
      </el-row> -->
    </div>
    <div v-if="update" class="title">
      <span class="input-span">
        <strong
          class="titleFont"
          @click="toBack"
        ><i style="padding-right: 6px" class="el-icon-arrow-left" />{{ $t('messages.updatePage') }}</strong>
      </span>
      <el-breadcrumb v-if="!tree" separator="/" class="bread">
        <el-breadcrumb-item
          v-for="(subProcess, subProcessIndex) in breadcrumbList"
          :key="subProcessIndex"
        >
          <a @click="jump(subProcess[0].processcode)">{{ subProcess[0].processcode }}:{{ subProcess[0].processname }}</a>
        </el-breadcrumb-item>
      </el-breadcrumb>
      <el-breadcrumb v-if="tree" separator="/" class="bread">
        <el-breadcrumb-item
          v-for="(subProcess, subProcessIndex) in breadcrumbList"
          :key="subProcessIndex"
        >
          <span>
            {{ subProcess.processcode }}:{{ subProcess.processname }}
          </span>
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div v-if="add" class="title">
      <span class="input-span">
        <strong class="titleFont" @click="toBack"><i style="padding-right: 6px" class="el-icon-arrow-left" />{{ $t('messages.addNewData') }}</strong>
      </span>
      <el-breadcrumb v-if="!tree" separator="/" class="bread">
        <el-breadcrumb-item
          v-for="(subProcess, subProcessIndex) in breadcrumbList"
          :key="subProcessIndex"
        >
          <a @click="jump(subProcess[0].processcode)">
            {{ subProcess[0].processcode }}:{{ subProcess[0].processname }}
          </a>
        </el-breadcrumb-item>
      </el-breadcrumb>
      <el-breadcrumb v-if="tree" separator="/" class="bread">
        <el-breadcrumb-item
          v-for="(subProcess, subProcessIndex) in breadcrumbList"
          :key="subProcessIndex"
        >
          <span>
            {{ subProcess.processcode }}:{{ subProcess.processname }}
          </span>
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div v-if="operate" class="title">
      <span class="input-span">
        <strong
          class="titleFont"
          @click="toBack"
        ><i style="padding-right: 6px" class="el-icon-arrow-left" />{{ $t('messages.disclosureMgmt') }}</strong>
      </span>
    </div>
    <div v-if="simulate" class="title">
      <span class="input-span">
        <strong
          class="titleFont"
          @click="toBack"
        ><i
          style="padding-right: 6px"
          class="el-icon-arrow-left"
        />{{ $t('messages.simulation') }}</strong>
      </span>
      <!-- <el-row>
        <el-button
          type="primary"
          class="seihin-button"
          @click="toNew()"
        >新製品を追加する&nbsp;＋</el-button>
      </el-row> -->
    </div>
    <div v-if="useradd" class="title">
      <span class="input-span">
        <strong
          class="titleFont"
          @click="toBack"
        ><i style="padding-right: 6px" class="el-icon-arrow-left" />新規ユーザー</strong>
      </span>
    </div>
    <div v-if="useredit" class="title">
      <span class="input-span">
        <strong
          class="titleFont"
          @click="toBack"
        ><i style="padding-right: 6px" class="el-icon-arrow-left" />ユーザー画面</strong>
      </span>
    </div>
    <!--add original nav-->
    <div v-if="original" class="title">
      <span class="input-span">
        <strong>{{ $t('messages.originalEFMgmn') }}</strong>
      </span>
    </div>
    <!--add  product nav-->
    <div v-if="product" class="title">
      <span class="input-span">
        <strong>{{ $t('messages.productMasterMgmt') }}</strong>
      </span>
    </div>
    <!--add  process nav-->
    <div v-if="process" class="title">
      <span class="input-span">
        <strong>{{ $t('messages.processFormatMgmt') }}</strong>
      </span>
    </div>
    <!--add  idearule nav-->
    <div v-if="basicRule" class="title">
      <span class="input-span">
        <strong>{{ $t('messages.efRullMgmt') }}</strong>
      </span>
    </div>
    <!--add  referenceindex nav-->
    <div v-if="referenceIndex" class="title">
      <span class="input-span">
        <strong
          class="titleFont"
          @click="toBack"
        ><i style="padding-right: 6px" class="el-icon-arrow-left" />{{ $t('messages.pro') }}</strong>
      </span>
    </div>
    <!--add  productList nav-->
    <div v-if="productList" class="title">
      <span class="input-span">
        <strong
          class="titleFont"
          @click="toBack"
        ><i style="padding-right: 6px" class="el-icon-arrow-left" />{{ $t('messages.productList') }}</strong>
      </span>
    </div>
    <!--add  downstream nav-->
    <div v-if="downstream" class="title">
      <span class="input-span">
        <strong>{{ $t('messages.manageSentRequests') }}</strong>
      </span>
    </div>
    <!--add  upstream nav-->
    <div v-if="upstream" class="title">
      <span class="input-span">
        <strong>{{ $t('messages.manageReceivedRequests') }}</strong>
      </span>
    </div>
    <div v-if="compareAbstract" class="title">
      <span class="input-span">
        <strong
          class="titleFont"
          @click="toBack"
        ><i style="padding-right: 6px" class="el-icon-arrow-left" />{{ $t('messages.compareAbstract') }}</strong>
      </span>
    </div>
    <div v-if="compareExplicit" class="title">
      <span class="input-span">
        <strong
          class="titleFont"
        >{{ $t('messages.compareDetails') }}</strong>
      </span>
    </div>
    <div v-if="referenceTree" class="title">
      <span class="input-span">
        <strong
          class="titleFont"
          @click="toBack"
        ><i style="padding-right: 6px" class="el-icon-arrow-left" />{{ $t('messages.referenceTree') }}</strong>
      </span>
    </div>
    <div v-if="report" class="title">
      <span class="input-span">
        <strong
          class="titleFont"
          @click="toBack"
        ><i style="padding-right: 6px" class="el-icon-arrow-left" />レポート作成</strong>
      </span>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/main'
export default {
  data() {
    return {
      dashboard: false,
      all: false,
      downstream: false,
      upstream: false,
      sansyo: false,
      update: false,
      add: false,
      tree: false,
      operate: false,
      path: '',
      breadcrumbList: {},
      loading4Single: true,
      loading4SeihinList: true,
      loading4Table: true,
      loading4Pie: true,
      simulate: false,
      useradd: false,
      useredit: false,
      original: false,
      product: false,
      process: false,
      basicRule: false,
      referenceIndex: false,
      productList: false,
      compareAbstract: false,
      compareExplicit: false,
      referenceTree: false,
      updateFromPage: '',
      report: false
    }
  },
  computed: {
    loading4Multiple() {
      const { loading4SeihinList, loading4Table, loading4Pie } = this
      return {
        loading4SeihinList,
        loading4Table,
        loading4Pie
      }
    }
  },
  watch: {
    loading4Multiple: {
      deep: true,
      handler(newVal) {
        if (
          !newVal.loading4SeihinList &&
          !newVal.loading4Table &&
          !newVal.loading4Pie
        ) {
          this.loading4Single = false
        } else {
          this.loading4Single = true
        }
      }
    },
    $route(to, from) {
      switch (to.path) {
        case '/pf/browser/dashboard':
          this.dashboard = true
          break
        case '/pf/browser/all':
          this.all = true
          break
        case '/pf/browser/referenceTree':
          this.referenceTree = true
          break
        case '/pf/requestManage/downstream':
          this.downstream = true
          break
        case '/pf/requestManage/upstream':
          this.upstream = true
          break
        case '/pf/browser/single':
          this.sansyo = true
          break
        case '/pf/input/update':
          this.update = true
          break
        case '/pf/input/add':
          this.add = true
          break
        case '/pf/operate/seihin':
          this.operate = true
          break
        case '/pf/input/simulate':
          this.simulate = true
          break
        case '/pf/user/add':
          this.useradd = true
          break
        case '/pf/user/edit':
          this.useredit = true
          break
        case '/pf/idea/original':
          this.original = true
          break
        case '/pf/productMaster/product':
          this.product = true
          break
        case '/pf/processFormat/process':
          this.process = true
          break
        case '/pf/ideaRule/basicRule':
          this.basicRule = true
          break
        case '/pf/browser/referenceIndex':
          this.referenceIndex = true
          break
        case '/pf/browser/productList':
          this.productList = true
          break
        case '/pf/compare/abstract':
          this.compareAbstract = true
          break
        case '/pf/compare/explicit':
          this.compareExplicit = true
          break
        case '/pf/reportCreation/report':
          this.report = true
          break
      }
      switch (from.path) {
        case '/pf/browser/dashboard':
          this.dashboard = false
          break
        case '/pf/browser/all':
          this.all = false
          break
        case '/pf/browser/referenceTree':
          this.referenceTree = false
          break
        case '/pf/requestManage/downstream':
          this.downstream = false
          break
        case '/pf/requestManage/upstream':
          this.upstream = false
          break
        case '/pf/browser/single':
          this.sansyo = false
          break
        case '/pf/input/update':
          this.update = false
          break
        case '/pf/input/add':
          this.add = false
          break
        case '/pf/operate/seihin':
          this.operate = false
          break
        case '/pf/input/simulate':
          this.simulate = false
          break
        case '/pf/user/add':
          this.useradd = false
          break
        case '/pf/user/edit':
          this.useredit = false
          break
        case '/pf/idea/original':
          this.original = false
          break
        case '/pf/productMaster/product':
          this.product = false
          break
        case '/pf/processFormat/process':
          this.process = false
          break
        case '/pf/ideaRule/basicRule':
          this.basicRule = false
          break
        case '/pf/browser/referenceIndex':
          this.referenceIndex = false
          break
        case '/pf/browser/productList':
          this.productList = false
          break
        case '/pf/compare/abstract':
          this.compareAbstract = false
          break
        case '/pf/compare/explicit':
          this.compareExplicit = false
          break
        case '/pf/reportCreation/report':
          this.report = false
          break
      }
    }
  },
  created() {
    this.isShow()
    EventBus.$on('toFloatContainer', (processList) => {
      this.breadcrumbList = processList
    })
    EventBus.$on('toTreeFloatContainer', (tree) => {
      this.tree = tree
    })
    EventBus.$on('updateFromPage', (updateFromPage) => {
      this.updateFromPage = updateFromPage
    })
    EventBus.$on('loading4SeihinList', (loading4SeihinList) => {
      this.loading4SeihinList = loading4SeihinList
    })
    EventBus.$on('loading4Table', (loading4Table) => {
      this.loading4Table = loading4Table
    })
    EventBus.$on('loading4Pie', (loading4Pie) => {
      this.loading4Pie = loading4Pie
    })
  },
  methods: {
    jump(domId) {
      const height =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop

      const dom = document.getElementById(domId)
      const domHeight = dom.offsetTop - 120

      var S = Number(height) - Number(domHeight)

      if (S < 0) {
        // scroll down
        S = Math.abs(S)
        window.scrollBy({ top: S, behavior: 'smooth' })
      } else if (S === 0) {
        // none
        window.scrollBy({ top: 0, behavior: 'smooth' })
      } else {
        // scroll up
        S = -S
        window.scrollBy({ top: S, behavior: 'smooth' })
      }
    },
    isShow() {
      this.path = this.$route.path
      switch (this.path) {
        case '/pf/browser/dashboard':
          this.dashboard = true
          break
        case '/pf/browser/all':
          this.all = true
          break
        case '/pf/requestManage/downstream':
          this.downstream = true
          break
        case '/pf/requestManage/upstream':
          this.upstream = true
          break
        case '/pf/browser/single':
          this.sansyo = true
          break
        case '/pf/input/update':
          this.update = true
          break
        case '/pf/input/add':
          this.add = true
          break
        case '/pf/operate/seihin':
          this.operate = true
          break
        case '/pf/input/simulate':
          this.simulate = true
          break
        case '/pf/user/add':
          this.useradd = true
          break
        case '/pf/user/edit':
          this.useredit = true
          break
        case '/pf/idea/original':
          this.original = true
          break
        case '/pf/productMaster/product':
          this.product = true
          break
        case '/pf/processFormat/process':
          this.process = true
          break
        case '/pf/ideaRule/basicRule':
          this.basicRule = true
          break
        case '/pf/browser/referenceIndex':
          this.referenceIndex = true
          break
        case '/pf/browser/productList':
          this.productList = true
          break
        case '/pf/compare/abstract':
          this.compareAbstract = true
          break
        case '/pf/compare/explicit':
          this.compareExplicit = true
          break
        case '/pf/browser/referenceTree':
          this.referenceTree = true
          break
        case '/pf/reportCreation/report':
          this.report = true
          break
      }
    },
    sethref(subProcessIndex) {
      return '#' + subProcessIndex
    },
    toNew() {
      this.$router.push({ path: '/pf/input/add' })
    },
    toAll() {
      this.$router.push({ path: '/pf/browser/productList' })
    },
    toDashboard() {
      this.$router.push({ path: '/pf/browser/dashboard' })
    },
    toBack() {
      if (this.all || this.useradd || this.useredit || this.add || this.update || this.original || this.product || this.process || this.referenceIndex || (this.update && this.updateFromPage === 'allPage')) {
        this.$router.push({ name: '/browser/productList' })
      } else if (this.productList) {
        this.$router.push({ name: '/browser/dashboard' })
      } else if (this.operate) {
        this.loading4Single = true
        this.$router.push({
          name: '/browser/single',
          params: {
            seihinCode: this.seihinCode
          }
        })
      } else if (this.sansyo || this.simulate || this.compareAbstract || this.referenceTree || this.report) {
        this.loading4Single = true
        this.$router.push({
          name: '/browser/referenceIndex',
        })
      }

      // this.$router.go(-1)
      // this.$router.push(
      //   { name: '/browser/single',
      //     params: {
      //       seihinCode: this.seihinCode
      //     }
      //   })
    }
  }
}
</script>

<style lang="scss" scoped>
.float-container {
  width: 100%;
  background-color: white;
  position: fixed;
  z-index: 3;
  margin-top: 0.2rem;
  .el-button--primary {
        color: #FFF;
        background-color: #1c97d8;
        border-color: #1c97d8;
        &:hover {
            color: #FFF;
            background-color: #0087CF;
            border-color: #0087CF;
        }
   }

  .title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    // padding-top: 1rem;
    // padding-left: 1.8rem;
    // padding-right: 1.8rem;
    // padding-bottom: 0.5rem;
    margin: 20px 24px 0;
    max-height: 47px;

    .itiran-span {
      font-size: 20px;
    }

    .input-span {
      font-size: 20px;
      min-width: 20%;

      .titleFont {
        cursor: pointer;
      }
    }

    .bread {
      font-family: "NotoSansCJKjp-Regular";
      font-size: 14px;
      color: #333333;
      letter-spacing: 0;
      text-align: center;
      font-weight: 400;
    }
  }
}

</style>
