import request from '@/utils/request'

export function getTransSenarioAPI(type) {
  const data = {
    type
  }
  console.log('call getTransSenarioAPI')
  return request({
    url: '/api/v1/transSenario/getTransSenario',
    method: 'post',
    data
  })
}
