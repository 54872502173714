import Decimal from './decimal'

// function times(val1, val2, val3) {
//     let temp1 = new Decimal(val1)
//     let temp2 = new Decimal(val2)
//     let temp3 = Decimal(0)
//     let temp4 = Decimal(0)
//     if (val3) {
//         temp3 = new Decimal(val3)
//         temp = temp1.times(temp2)
//         tem4 = temp.times(temp3)
//     } else {
//         temp4 = temp1.times(temp2)
//     }
//     return temp4
// }

export function times(val1, ...val2) {
  let temp1 = new Decimal(val1)
  for (let tempNum of val2) {
    temp1 = temp1.times(new Decimal(tempNum))
  }
  return temp1
}

export function divide(val1, val2) {
  let temp1 = new Decimal(val2)
  let temp2 = val1.dividedBy(temp1)
  return temp2
}
