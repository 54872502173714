export default {
    messages: {
        //menu begin
        dashboardTitle: 'ダッシュボード',
        calculationTitle: '算定データ',
        emissionsTitle: '排出量算定',
        registerTitle: '新規データの登録',
        temporaryEditTitle: '一時保存データの編集',
        finalizedEditTitle: '確定保存データの編集',
        lookUpTitle: '排出量参照',
        simulationTitle: 'シミュレーション',
        reportTitle: '報告・収集',
        disclosureTitle: '情報公開管理',
        requestTitle: '依頼管理',
        receivedTitle: '被依頼管理',
        manageMasterTitle: 'マスター管理',
        productMasterTitle: '製品マスター管理',
        originalTitle: '原単位一覧・管理',
        processTitle: 'プロセスフォーマット管理',
        ruleTitle: '原単位紐づけルール管理',
        guidelinesTitle: 'ガイドライン・マニュアル',
        caltGuidelinesTitle: '算定ガイドライン',
        videoTitle: 'LCA研修動画',
        checkListTitle: 'チェックリスト',
        uerManualTitle: 'システムマニュアル',
        emissionsCompareTitle: '排出量比較',
        overviewCompareTitle: '概要の比較',
        detailsCompareTitle: '詳細の比較',
        logout: 'ログアウト',
        //end
        kpiTitle: 'KPI・算定進捗',
        companyAll: '全社',
        importName: '目標設定',
        errorTitle: 'エラー',
        calculatedProduct: '算定済み' + '　　　' + '製品数',
        calculatedProduct1: '算定済み',
        calculatedProduct2: '製品数',
        calculatedProducts: '算定済み　製品数',
        target: '目標',
        progressRate: '進捗率',
        progress: '進捗率',
        ideaUserRate: '他社算定原単位の使用状況',
        ratio: '割合',
        percentage: '割合',
        usage: '活用数',
        totalA1Number: 'A1の管理項　　　目数合計',
        totalItemsA1: 'A1の管理　　　項目数合計',
        totalItemsA11: 'A1の管理',
        totalItemsA12: '項目数合計',
        checkList: 'チェックリスト – 達成状況',
        totalNumberCalculated: '算定数合計',
        averagePoints: 'チェックリスト平均点',
        fullPoint: '17点満点の算定数',
        perfectScore: '満点の割合',
        fullPointPercentage: '満点の割合',
        division: '部門',
        responses2Partners: '川下企業への回答数',
        response2Customers: '回答数',
        response2Supliers: '回答数',
        receivedNumber: '被依頼数',
        requestNumber: '依頼数',
        partnersResponses: '川上企業からの回答数',
        checkStatus: 'チェックリスト – 項目別チェック状況',
        unknownTip: '全社を選択してください',
        productList: '製品一覧',
        checkPerf: 'チェックリスト　– 達成状況',
        productListPage: '製品一覧画面',
        //downstream
        manageSentRequests: '依頼管理画面',
        responsesReceived: '回答受領数合計',
        requestsSent: '依頼数合計',
        addRequest: '依頼入力',
        company: '企業名',
        requests: '依頼数',
        responses: '回答受領数',
        requestsDate: '依頼日',
        memo: '依頼内容メモ',
        cancel: 'キャンセル',
        doRequest: '依頼する',
        //upstream
        manageReceivedRequests: '被依頼管理画面',
        responsesSent: '回答数合計',
        requestsReceived: '被依頼数合計',
        received: '被依頼数',
        responsesSent: '回答済数',
        receivedDate: '被依頼日',
        requestRoute: '依頼経路',
        makeOfflineRequest: 'オフライン依頼入力',
        save: '保存',
        number: '依頼数',
        //productList
        selfCalculated: '自社算定',
        calculatedbyPartners: '他社算定',
        newCalculation: '＋新規算定',
        product: '製品',
        divisionP: '主管部門',
        lastUpdatedBy: '最終更新ユーザー',
        saveStatus: '保存ステータス',
        lastUpdate: '最終更新日時',
        reports2Partners: '報告企業数',
        emissionc: '排出量(kg-CO₂eq)',
        emissionc1:'(kg-CO₂eq)',
        checkScore: 'チェック数',
        ratioOfEF: '他社算定原単位排出量割合',
        menu: 'メニュー',
        ownEF: '自社算定原単位',
        details: '詳細を表示する',
        editData: 'データを編集する',
        editData1: 'データを編集する',
        deleteData: '製品データを削除する',
        deleteData1: '製品データを削除する',
        copyData: '製品データをコピーする',
        linkedOwnSystem: '自社システム連携',
        dataID: 'データID',
        linkageDate: '連携日時',
        linkedSystem: '連携元システム',
        processCategory: 'プロセス区分',
        numberItems: '管理項目数',
        Memo1: '備考',
        confirmLinkage: 'API連携結果確認',
        pProduct: '製品名',
        pMaster: '製品マスターの入力',
        pVersion: 'バージョンの入力',
        pMessage: '製品マスターに登録されていない製品のため、登録してください',
        pDuplicateMessage: '当該製品のバージョンに重複がある為、変更してください',
        //other
        companyname: '会社名',
        disclosureDate: '公開日時',
        disclosureLevel: '公開区分',
        auth1: 'レベル１：総排出量の開示',
        auth5: 'レベル２：総排出量の開示・算定内訳の一部公開',
        auth4: 'レベル３：総排出量の開示・算定内訳の全公開',
        //add
        addNewData: '登録画面（追加）',
        standardProcess: '標準プロセス',
        originalProcess: 'オリジナルプロセス',
        productName: '製品名',
        productMasterName: '製品名',
        addproductName: '製品名',
        unit: '単位',
        deputname: '副単位の有無',
        version: 'バージョン',
        yieldRate: '歩留まり率',
        checkRegister: '入力',
        viewGuidelines: 'ガイドライン表示',
        uploadCSV: '転記シートアップロード',
        itemName: '項目名',
        managedItem: '管理項目名',
        ef: '原単位',
        efName: '原単位名',
        a2Calc: 'A2計算',
        amount: '活動量',
        intermediateProduct: 'タグ',
        addNewItem: '新規項目',
        addNewProcess: '新規プロセス',
        saveTemp: '一時保存',
        calculate: '算定',
        utilityDistribution: 'ユーティリティ按分',
        productTransport: '製品輸送',
        entryAssistMenu: '入力補助メニュー',
        opuc: '工場全体のユーティリティ消費量(年間)',
        overallPlantProduction: '工場全体の生産量',
        productWeight: '製品の重量',
        electricity: '電力',
        waterSuplly: '水道',
        fuel: '燃料',
        item1: '市内もしくは近隣市間の場合',
        item2: '県内の場合',
        item3: '県間輸送の可能性がある場合',
        item4: '特定地域に限定されない場合（国内）',
        item5: '海外陸送の場合',
        transportMeans: '輸送手段',
        roadTransportOnly: '陸送のみ',
        includingSeaTransport: '海運含む',
        transportScenario: '輸送距離シナリオ',
        tranCheck: '輸送手段を入力してください',
        tranSpotCheck: '輸送距離を入力してください',
        transportDistance: '輸送距離',
        backName: '戻る',
        pets: '輸送距離シナリオを入力してください',
        petm: '輸送手段を選択してください',
        petd: '輸送距離を入力してください',
        ccr: '計算して結果を確認する',
        fileUploadResults: 'ファイル読込結果確認',
        process: 'プロセス',
        item: '管理項目',
        itemA: '項目A',
        itemB: '項目B',
        itemC: '項目C',
        itemD: '項目D',
        itemE: '項目E',
        finalized: '確定',
        inputData: '入力時',
        afterUpdate: '原単位名（更新後）',
        beforUpdate: '原単位名（更新前）',
        tfihbl2anef: '以下の項目は新規の紐づけで原単位が設定されています',
        pttiyw2urarutnef: '原単位紐づけルールを更新する場合は、更新対象の項目にチェックを付けてください',
        onlyEmissions: '排出量のみ',
        whetherRegister: '登録しますか？',
        onlyEmissionsRule: '排出量と紐づけルールを',
        doregister: '登録する',
        docancel: 'キャンセル',
        tsic: '一時保存が完了しました',
        wibo2dtri: '該当項目を削除してよろしいでしょうか',
        tepvahdritfss: '指定された製品・バージョンは既に確定保存状態で登録済みのデータがあります。',
        potesftrsofpaeted: '当該製品の参照画面から編集画面を開き、排出量データの編集をしてください。',
        tifiiacbr: 'インポートファイルに不備があり登録できません。csvをダウンロードしてエラー内容をご確認ください。',
        note: 'メモを記載',
        uploadTitle: '排出量を登録しますか？',
        addRuleButton: '排出量とルールの登録',
        ruleCancelMessage: '操作データは保存されません。元の画面に戻りますか？',
        changeButtonMessage: '入力データは削除されますがよろしいですか？',
        noParent: '親子関係なし',
        parentExists: '親子関係あり',
        parentItem: '親管理項目',
        itemContent: '管理している原材料の名称や、使用しているエネルギーの種類、輸送手段、廃棄手段など。',
        efContent: '“項目名”に対応するIDEAの原単位名または、各企業が登録した製品名称（原単位）を選択します。',
        amountContent: '“項目名”の投入量や排出量、消費量などの数値を入力します。入力する数値は“単位”に合わせ、必要に応じて単位換算を行ってください。',
        unitContent: '“原単位名”を選択すると、自動で表示されます。',
        calQualityList: '算定品質チェックリスト',
        calLCAList: 'LCA計算チェックリスト',
        check: 'チェック',
        sumemissionc: '総排出量(kg-CO₂eq)',
        formatNoParent: 'フォーマットダウンロード（親子関係なし）',
        formatParent: 'フォーマットダウンロード（親子関係あり）',
        EFAnalogy: '原単位推測',
        A2Calculate: '上流輸送計算(A2)',
        lastUpdatedBy1: '最終更新ユーザー',
        EFUnconvertible: '選択された原単位の単位を重量に換算できないため、A2の排出量を当機能では計算できません',
        unitMismatched: '入力された単位が原単位側の単位と合致しないため、活動量と単位を確認してください',
        checkAmount: '原単位を設定後に活動量を確認してください',
        processAfter: 'プロセス（更新後）',
        processBefore: 'プロセス（更新前）',
        EFChanged: '該当原単位は変更されているため、単位、排出量を確認してください',
        subunitChanged: '副単位が更新されたため見直してください',
        EFDelete: '原単位が削除されているので、変更してください',
        subunitDelete: '副単位は削除されたため見直してください',
        callParts: 'データ呼び出し',
        copyLine: '直前行コピー',
        enterProduct: '呼び出す製品バージョンと対象の部分を選択してください',
        productAndVersion: '製品・バージョン',
        callData: '呼び出しデータ',
        callDataDelMsg :'該当項目を削除してよろしいでしょうか',
        callDataPorduct: '他製品バージョンからの呼び出しデータ',
        //referenceIndex
        pro: '製品参照：概要',
        productVersion: '製品・バージョン',
        productOverview: '製品の概要',
        viewDetails: '詳細を見る',
        emission: '排出量',
        reduction: '削減幅',
        cwop: '他製品との比較',
        eotpv: '本製品バージョンの排出量',
        uopef: '他社算定原単位の使用',
        usedef: '使用した原単位数',
        rwte: '排出量に占める割合',
        items: '個',
        car2c: 'チェックリストと川下企業への報告',
        checkItems: 'チェック済みの項目数',
        reported2Partners: '報告設定企業数',
        companies: '企業',
        hotspotRanking: 'ホットスポットランキング',
        comparewith: '比較対象',
        psp: '製品を選択してください',
        rmp: '原材料調達',
        transportation: '輸送',
        production: '生産',
        usage: '使用',
        disposalRecycling: '廃棄・リサイクル',
        noShow: '非開示プロセス合計',
        other: 'その他',
        selfCompany: '———自社製品———',
        otherCompany: '———他社製品———',
        triangleTip: '川上企業の公開設定によっては、原材料調達～廃棄・リサイクルのカードの排出量に含まれていない分が存在し、それらの合計は非開示プロセス合計のカードに合算されています',
        ownEFRegister: '自社算定原単位化',
        ownEFRegComplete: '自社算定原単位登録済み',
        ownEFRegisterTip: '算定した結果を原単位として、別の製品を算定する際に使用できます',
        ownEFRegPopUp: '自社算定原単位に登録しますか？',
        compare: '概要の比較',
        treeList: 'ツリー図',
        referSubUnit: 'サブ単位参照',
        productUnit: '製品単位',
        conversionCoef: '換算係数',
        mainUnit: 'メイン単位',
        subunit1: 'サブ単位1',
        subunit2: 'サブ単位2',
        subunit3: 'サブ単位3',
        //singleWithFold
        prd: '製品参照：詳細',
        updatePage: '登録画面（更新）',
        totalEmissions: '排出量合計',
        totalEmissions1: '排出量合計',
        totalEmissions2: '排出量合計',
        edit: '編集する',
        disclosure: '情報公開管理画面',
        downloadcsv: 'データダウンロード',
        total: '合 計',
        noIntermediatePro : 'タグなし',
        //simulation
        simulation: '排出量シミュレーション画面',
        simulationValue: 'シミュレーション値',
        actualFigure: '算定値',
        processName: 'プロセス名',
        back: '戻 る',
        done: '完了',
        saap: '代替案として保存する',
        resetSim: 'シミュレーションをリセット',
        resetSimPopUp: 'シミュレーションデータをリセットしてもよいですか？',
        resetBtn: 'リセットする',
        //seihin
        disclosureMgmt: '情報公開管理画面',
        dosave: '保 存',
        companylist: '会社の一覧',
        selectAll: '全選択',
        level: 'レベル',
        permission: '総排出量の開示',
        selectItems: '対象項目選択',
        permission2: '総排出量の開示・算定内訳の',
        limited: '一部',
        full: '全',
        disclosure2: '公開',
        confirm: '確認',
        disclosureMgmt2: '川上企業の情報公開画面',
        pstp: '会社名を入力してください',
        checkProductName: '製品を選択してください',
        //product
        productMasterMgmt: '製品マスター管理',
        productRegistration: '製品登録',
        productModelNum: '製品型番',
        externalModelNum: '社外型番',
        category: '製品分類',
        register: '登録',
        listOfEF: '登録済製品一覧',
        registeredBy: '登録者',
        updateBy: '更新者',
        registeredDate: '登録日',
        registeredDate1: '更新日',
        //original
        originalEFMgmn:'原単位一覧・管理画面',
        efRegister:'原単位登録',
        emissionFactor:'排出係数',
        listOfOriginalEF:'原単位一覧',
        ideaCode:'IDEAコード',
        ideaType: '原単位分類',
        remarks:'備考',
        CSVImport:'ファイルインポート',
        aAll:'全て',
        ideaEF:'IDEA原単位',
        originalEF:'オリジナル原単位',
        oOwnEF:'自社算定原単位',
        partnerEF:'他社算定原単位',
        deleteWarn:'この操作はデータを完全に削除します。削除してよいですか？',
        Confirmation:'確認',
        eEFRegister:'原単位登録',
        masterUnit: '主単位',
        seccondUnitReg: '副単位の新規登録',
        seccondUnitReged: '登録済みの副単位',
        conversionRate: '副：主の比率',
        unitCheckMsg: '単位を入力してください',
        conversionRateCheckMsg: '副：主の比率を入力してください',
        //process
        processFormatMgmt: 'プロセスフォーマット管理',
        code: 'コード',
        correspondingStandardProcess: '対応する17段階',
        operation: '操作する',
        cdhnbsR2ps: '操作データは保存されません。元の画面に戻りますか？',
        addANewItem: '新規項目追加',
        processHeaderName: 'プロセス名',
        yes: 'はい',
        //basicRule
        efRullMgmt: '原単位紐づけルール管理画面',
        registeredEFRule: '登録済の原単位紐づけルール',
        addNewRule: '新規ルール追加',
        addNewRule1: 'Add new EF rule',
        domenu: '操作',
        psaEF2er: '各ルールに原単位を紐づけてください',
        originalDel: 'この原単位は削除されています',
        updateRule: '更新',
        repeatRuleWarningPopup1: '既存のルールと項目A～項目Eが重複しています',
        repeatRuleWarningPopup2: 'ー　新たにルールを追加したい場合は項目A～項目Eに既存ルールと重複がないようにしてください',
        repeatRuleWarningPopup3: 'ー　既存のルールを更新したい場合はこの画面を閉じて、ルール更新ボタンから更新してください',
        editEFRule: 'ルール更新',
        deleteEFRule: 'ルール削除',
        registerEFRule: '新規ルールの追加',
        formatImport: 'ファイル一括登録',
        formatDownload: 'フォーマットダウンロード',
        aAddNewEFRule: '新規紐づけ追加',
        editExistEFRule: '既存ルールの更新',
        originalDel1: 'この原単位は削除されています',
        //processCode
        newProcess: '新規プロセス',
        all: '全て',
        a1ToA3: 'A1~A3:生産段階',
        a4ToA5: 'A4~A5:建設段階',
        b1ToB7: 'B1~B7:使用段階',
        c1ToC4: 'C1~C4:廃棄·リサイクル段階',
        dTitle: 'D:間接影響',
        A1: '原材料・部材調達',
        A2: '上流輸送（原材料・部材）',
        A3: '製品製造',
        A4: '下流輸送（製品）',
        A5: '施工・設置',
        B1: '使用（エネルギー・水以外）',
        B2: 'メンテナンス',
        B3: '修理',
        B4: '交換',
        B5: '改装',
        B6: '製品使用時のエネルギー使用',
        B7: '製品使用時の水使用',
        C1: '撤去・解体',
        C2: '使用済製品の輸送',
        C3: '使用済製品の中間処理',
        C4: '廃棄物処理',
        D: '間接影響（リサイクル効果等）',
        processCancel: 'キャンセル',
        processAdd: 'プロセスを追加する',
        operateVideo: 'お役立ち・操作説明動画',
        ideaSimpleData: 'IDEA サンプルデータ',
        termsAndConditions: '利用規約・手続き関連',
        calculationSupport: '算定サポート',
        lcaPlustermAndCon: 'LCA Plus利用規約',
        licensetermAndCon: 'IDEAライセンス規約',
        //update
        wyl2utd: '更新しますか？',
        update: '更新する',
        manageName: '管理項目名',
        eFName: '原単位名',
        unit1: '単 位',
        processContent: '今回、算定の対象とするプロセスを選択してください。対象外のプロセスは選択する必要はありません。原則として、A1、A2、A3は選択してください。',
        aAmountContent: '“項目名”の投入量や排出量、消費量などの数値を入力します。入力する数値は“単位”に合わせ、必要に応じて単位換算を行ってください。',
        //referenceTree
        referenceTree: '製品参照：ツリー',
        iItem: '項目',
        eF: '原単位',
        //compareAbstract
        compareAbstract: '排出量比較：概要',
        tTotalEmissions: '排出量合計(kg-CO₂eq)',
        standard: '基準',
        standardReduce: '削減幅',
        eEmissions: '排出量(kg-CO₂eq)',
        compareDetailedTip: '排出量の詳細を比較する対象の製品にチェックしてください',
        abProductVersion: '製品・バージョン',
        detailComparison: '詳細の比較',
        //compareExplicit
        compareDetails: '排出量比較：詳細',
        pProductVersion: '製品・バージョン',
        product1: '製品1',
        product2: '製品2',
        eEmission: '排出量(kg-CO₂eq)',
        pPercentage: '割 合',
        yYieldRate: '歩留まり率',
        tTotal: '合計',
        declineTip: '排出量が減少している製品をハイライトしています',
        //error msg
        peapn: '製品名を入力してください',
        peapnThen: '製品名を入力してください',
        peavn: 'バージョンを入力してください',
        peefn: '原単位名を入力してください',
        peefnOri: '原単位名を入力してください',
        peaam: '活動量を入力してください',
        peain: '項目名を入力してください',
        peopuca: '工場全体のユーティリティ消費量（年間）を入力してください',
        penopp: '工場全体の生産量を入力してください',
        pepw: '製品の重量を入力してください',
        atiictcpinc: '管理項目にチェックがついていますが、該当するプロセスにチェックがされていません',
        pctcpaw: '正しくプロセスにもチェックしてください',
        targetProcess: '対象プロセス',
        peangt0alt100: '0より大きく、100未満の数値を入力してください',
        successfullyRegistered: '登録に成功しました!',
        petu: '単位を選択してください',
        petdept: '主管部門を入力してください',
        prapnsiidwtarpn: '既に登録済みの製品名と重複しているため、別の製品名で登録してください',
        petef: '係数を記入してください',
        petp: 'プロセス名を入力してください',
        halfNumber: '半角数値を入力してください',
        confirmDeleteMessage: '削除してよろしいでしょうか',
        buttonDeleteConfirm: '削除する',
        buttonCancel: 'キャンセル',
        atLeastOneProcess: 'プロセスは一つ以上してください。',
        atLeastOneProcessButton: '了解',
        originMsg: '既に登録済みの原単位名と重複しているため、別の原単位名で登録してください',
        popupTyep: '確認',
        csvTitle1: '対象',
        csvTitle2: 'エラーメッセージ',
        pstsp: '段階を選択してください',
        download: 'ダウンロードする',
        deletButton: '削除する',
        simulatCheckItem: '項目名を入力してください',
        simulatCheckIdea: '原単位名を入力してください',
        simulateCheckRightIdea: '正しい原単位を入力してください',
        simulateCheckRightNumber: '0を入力しないでください',
        simulatCheckQut: '活動量を入力してください',
        simulatCheckBuri: '0より大きく、100未満の数値を入力してください',
        addCheckParSame: '管理項目名と同じ項目名は、指定できません',
        addCheckParNo: '親となる行の管理項目名を入力してください',
        oriCsvTitle1: '対象',
        oriCsvTitle2: 'エラーメッセージ',
        firstManagedProgram: '最上位管理項目',
        managedProgramCount: '管理項目数',
        updateUser:'更新者',
        updateDate:'更新日',
        getData:'呼び出しデータ'

    }
}