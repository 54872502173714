<template>
  <transition name="popup-fade">
    <div class="popup-body">
      <div class="popup-conten">
        <div class="popup-title">
          <span class="title-font">製品情報</span>
        </div>
        <div class="popup-seihin-content">
          <el-form label-position= "left" label-width="150px">
            <el-form-item label="主管部門名" style="width: 100%; border-bottom: 2px solid #e7e9ea;">
              <div :class="seihinMasterData.bumonname.length > 50 ? 'value-class':'value-class-break'">
                <span style="word-break: break-all;">{{ seihinMasterData.bumonname }}</span>
              </div>
            </el-form-item>
            <el-form-item label="製品型番" style="width: 100%; border-bottom: 2px solid #e7e9ea;">
              <div :class="seihinMasterData.productno.length > 50 ? 'value-class':'value-class-break'">
                <span style="word-break: break-all;">{{ seihinMasterData.productno }}</span>
              </div>
            </el-form-item>
            <el-form-item label="社外型番" style="width: 100%; border-bottom: 2px solid #e7e9ea;">
              <div :class="seihinMasterData.shagaiproductno.length > 50 ? 'value-class':'value-class-break'">
                <span style="word-break: break-all;">{{ seihinMasterData.shagaiproductno }}</span>
              </div>
            </el-form-item>
            <el-form-item label="製品分類" style="width: 100%; border-bottom: 2px solid #e7e9ea;">
              <div :class="seihinMasterData.seihinbunrui.length > 50 ? 'value-class':'value-class-break'">
                <span style="word-break: break-all;">{{ seihinMasterData.seihinbunrui }}</span>
              </div>
            </el-form-item>
            <el-form-item style="width: 100%; border-bottom: 2px solid #e7e9ea;">
              <template slot="label">
                <el-tooltip :content="columnNameData.columnName1" :disabled="columnNameData.columnName1.length < 6">
                  <span class="label-class">{{ gettextlenght(columnNameData.columnName1) }}</span>
                </el-tooltip>
              </template>
              <div :class="seihinMasterData.columnvalue1.length > 50 ? 'value-class':'value-class-break'">
                <span style="word-break: break-all;">{{ seihinMasterData.columnvalue1 }}</span>
              </div>
            </el-form-item>
            <el-form-item style="width: 100%; border-bottom: 2px solid #e7e9ea;">
              <template slot="label">
                <el-tooltip :content="columnNameData.columnName2" :disabled="columnNameData.columnName2.length < 6">
                  <span class="label-class">{{ gettextlenght(columnNameData.columnName2) }}</span>
                </el-tooltip>
              </template>
              <div :class="seihinMasterData.columnvalue2.length > 50 ? 'value-class':'value-class-break'">
                <span style="word-break: break-all;">{{ seihinMasterData.columnvalue2 }}</span>
              </div>
            </el-form-item>
            <el-form-item style="width: 100%; border-bottom: 2px solid #e7e9ea;">
              <template slot="label">
                <el-tooltip :content="columnNameData.columnName3" :disabled="columnNameData.columnName3.length < 6">
                  <span class="label-class">{{ gettextlenght(columnNameData.columnName3) }}</span>
                </el-tooltip>
              </template>
              <div :class="seihinMasterData.columnvalue3.length > 50 ? 'value-class':'value-class-break'">
                <span style="word-break: break-all;">{{ seihinMasterData.columnvalue3 }}</span>
              </div>
            </el-form-item>
            <el-form-item style="width: 100%; border-bottom: 2px solid #e7e9ea;">
              <template slot="label">
                <el-tooltip :content="columnNameData.columnName4" :disabled="columnNameData.columnName4.length < 6">
                  <span class="label-class">{{ gettextlenght(columnNameData.columnName4) }}</span>
                </el-tooltip>
              </template>
              <div :class="seihinMasterData.columnvalue4.length > 50 ? 'value-class':'value-class-break'">
                <span style="word-break: break-all;">{{ seihinMasterData.columnvalue4 }}</span>
              </div>
            </el-form-item>
            <el-form-item style="width: 100%">
              <template slot="label">
                <el-tooltip :content="columnNameData.columnName5" :disabled="columnNameData.columnName5.length < 6">
                  <span class="label-class">{{ gettextlenght(columnNameData.columnName5) }}</span>
                </el-tooltip>
              </template>
              <div :class="seihinMasterData.columnvalue5.length > 50 ? 'value-class':'value-class-break'">
                <span style="word-break: break-all;">{{ seihinMasterData.columnvalue5 }}</span>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div class="button-class">
          <el-button type="primary" size="small" @click="doClose">閉じる</el-button>
        </div>
      </div>
    </div>
  </transition>
</template>
<script type="text/ecmascript-6">
export default {
  name: 'Popup',
  props: {
    isShowPopup: Boolean,
    columnNameDataRe: {
      type: Object,
      default: () => {}
    },
    seihinMasterDataRe: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      columnNameData: {
        kigyouCode: '',
        columnName1: '任意項目１',
        columnName2: '任意項目２',
        columnName3: '任意項目３',
        columnName4: '任意項目４',
        columnName5: '任意項目５',
        upduser: ''
      },
      seihinMasterData: {
        bumonname: '',
        productno: '',
        shagaiproductno: '',
        seihinbunrui: '',
        columnvalue1: '',
        columnvalue2: '',
        columnvalue3: '',
        columnvalue4: '',
        columnvalue5: ''
      }
    }
  },
  mounted() {
    if (this.columnNameDataRe) {
      this.columnNameData = this.columnNameDataRe
    }
    this.seihinMasterData = this.seihinMasterDataRe
  },
  methods: {
    gettextlenght(str) {
      if (str.length > 6) {
        str = str.substr(0, 6) + '...'
      }
      return str
    },
    getValuelenght(str) {
      if (str) {
        if (str.length > 50) {
          str = str.substr(0, 50) + '...'
        }
      }
      return str
    },
    doClose() {
      this.$emit('seihinMasterCall', { 'isShowPopup': false })
    },
  }
}
</script>
<style lang="scss">
.popup-body {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 998;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    .popup-conten {
        position: absolute;
        background-color: white;
        width: 45%;
        height: 70%;
        margin-left: 30%;
        margin-top: 7%;
        border-radius: 5px;
    }
    .popup-title {
        height: 7%;
        font-size: 2rem;
        padding-top: 1rem;
        margin-left: 1rem;
    }
    .value-span {
        margin-left: 2rem;
    }
    // .el-form-item {
    //     margin-bottom: 0.5rem;
    // }
    .button-class {
        margin-top: 3rem;
        width: 100%;
        margin-left: 47%;
    }
    .popup-seihin-content {
        height: 70%;
        margin-top: 2rem;
        padding-left: 5rem;
        padding-right: 5rem;

        .el-form-item {
            margin-bottom: 0rem;
        }
        .el-form-item__content {
            line-height: 20px;
            position: relative;
            font-size: 14px;
        }

        .label-class {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            background-color: #f5f5f5;
        }

        .value-class {
            margin-left: 2rem;
        }

        .value-class-break {
           margin-left: 2rem;
           line-height: 40px;
        }

        .el-form-item__label {
            text-align: left;
            padding-left: 2rem;
            font-weight: 700;
            line-height: 40px;
            background-color: #f5f5f5;
        }
    }
}
.el-tooltip__popper {
    position: absolute;
    border-radius: 4px;
    max-width: 1000px;
    padding: 10px;
    z-index: 2000;
    font-size: 12px;
    line-height: 1.2;
    min-width: 10px;
    word-wrap: break-word;
}
</style>
