import Vue from 'vue'
import Router from 'vue-router'
import store from '../store/index'

Vue.use(Router)

/* Layout */
import Layout from '@/layout'

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'/'el-icon-x' the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [
  {
    path: '/',
    name: '/browser/lp',
    component: r => require.ensure([], () => r(require('@/views/lp/landing')), 'landing')
  },
  {
    path: '/index',
    component: r => require.ensure([], () => r(require('@/views/login/index')), 'index'),
    hidden: true
  },
  {
    path: '/404',
    component: r => require.ensure([], () => r(require('@/views/404')), 'error'),
    hidden: true
  },

  {
    path: '/pf/pdf',
    component: r => require.ensure([], () => r(require('@/views/pdf')), 'pdf'),
    hidden: true
  },
  {
    path: '/pf/checklist',
    component: r => require.ensure([], () => r(require('@/views/checklist')), 'checklist'),
    hidden: true
  },
  {
    path: '/pf/refresh',
    name: '/refresh',
    component: r => require.ensure([], () => r(require('@/views/refresh')), 'refresh'),
    hidden: true
  },

  // {
  //   path: '/browser/single',
  //   name: '/browser/single',
  //   component: () => import('@/views/browser/single'),
  //   hidden: true
  // },

  // {
  //   path: '/input/update',
  //   name: '/input/update',
  //   component: () => import('@/views/input/update'),
  //   hidden: true
  // },

  // {
  //   path: '/input/new',
  //   name: '/input/new',
  //   component: () => import('@/views/input/new'),
  //   hidden: true
  // },

  {
    path: '/pf/browser',
    component: Layout,
    children: [
      {
        path: 'dashboard',
        name: '/browser/dashboard',
        component: () => import('@/views/dashboard/dashboard'),
        // component: r => require.ensure([], () => r(require('@/views/dashboard/dashboard')), 'dashboard'),
        meta: { title: '製品一覧画面', icon: 'form', requireAuth: true }
      },
      // {
      //   path: 'all',
      //   name: '/browser/all',
      //   component: () => import('@/views/browser/all'),
      //   meta: { title: '参照', icon: 'form', requireAuth: true }
      // },
      {
        path: 'single',
        name: '/browser/single',
        component: r => require.ensure([], () => r(require('@/views/browser/singleWithFold')), 'singleWithFold'),
        meta: { title: '参照', icon: 'form', requireAuth: true }
      },
      {
        path: 'referenceIndex',
        name: '/browser/referenceIndex',
        component: () => import('@/views/browser/referenceIndex'),
        meta: { title: '製品参照：概要', icon: 'form', requireAuth: true }
      },
      {
        path: 'referenceTree',
        name: '/browser/referenceTree',
        component: r => require.ensure([], () => r(require('@/views/browser/referenceTree')), 'referenceTree'),
        meta: { title: '製品参照：ツリー', icon: 'form', requireAuth: true }
      },
      {
        path: 'productList',
        name: '/browser/productList',
        component: r => require.ensure([], () => r(require('@/views/browser/productList')), 'productList'),
        meta: { title: '製品一覧', icon: 'form', requireAuth: true }
      }
    ]
  },
  {
    path: '/pf/operate',
    component: Layout,
    children: [
      {
        path: 'seihin',
        name: '/operate/seihin',
        component: r => require.ensure([], () => r(require('@/views/operate/seihin')), 'seihin'),
        meta: { title: '情報公開', icon: 'form', requireAuth: true }
      }
    ]
  },
  {
    path: '/pf/requestManage',
    component: Layout,
    children: [
      {
        path: 'downstream',
        name: '/requestManage/downstream',
        component: r => require.ensure([], () => r(require('@/views/requestManage/downstream')), 'downstream'),
        meta: { title: '依頼管理', icon: 'form', requireAuth: true }
      }
    ]
  },
  {
    path: '/pf/requestManage',
    component: Layout,
    children: [
      {
        path: 'upstream',
        name: '/requestManage/upstream',
        component: r => require.ensure([], () => r(require('@/views/requestManage/upstream')), 'upstream'),
        meta: { title: '被依頼管理', icon: 'form', requireAuth: true }
      }
    ]
  },
  {
    path: '/pf/input',
    component: Layout,
    children: [
      {
        path: 'update',
        name: '/input/update',
        component: r => require.ensure([], () => r(require('@/views/input/update')), 'update'),
        meta: { title: '入力', icon: 'form', requireAuth: true }
      },
      {
        path: 'add',
        name: '/input/add',
        component: () => import('@/views/input/add'),
        // component: r => require.ensure([], () => r(require('@/views/input/add')), 'add'),
        meta: { title: '入力', icon: 'form', requireAuth: true }
      },
      {
        path: 'simulate',
        name: '/input/simulate',
        component: r => require.ensure([], () => r(require('@/views/input/simulate')), 'simulate'),
        meta: { title: 'シミュレーション ', icon: 'form', requireAuth: true }
      }
    ]
  },
  {
    path: '/pf/reportCreation',
    component: Layout,
    children: [
      {
        path: 'report',
        name: '/reportCreation/report',
        component: r => require.ensure([], () => r(require('@/views/reportCreation/report')), 'report'),
        meta: { title: 'レポート作成', icon: 'form', requireAuth: true }
      }
    ]
  },
  {
    path: '/pf/user',
    component: Layout,
    children: [
      {
        path: 'edit',
        name: '/user/edit',
        component: r => require.ensure([], () => r(require('@/views/user/edit')), 'edit'),
        meta: { title: '情報変更', icon: 'form', requireAuth: true }
      },
      // {
      //   path: 'add',
      //   name: '/user/add',
      //   component: () => import('@/views/user/add'),
      //   meta: { title: '新規ユーザー', icon: 'form', requireAuth: true }
      // }
    ]
  },
  {
    path: '/pf/idea',
    component: Layout,
    children: [
      {
        path: 'original',
        name: '/idea/original',
        component: r => require.ensure([], () => r(require('@/views/idea/original')), 'original'),
        meta: { title: '原単位一覧・管理', icon: 'form', requireAuth: true }
      }
    ]
  },
  {
    path: '/pf/productMaster',
    component: Layout,
    children: [
      {
        path: 'product',
        name: '/productMaster/product',
        component: r => require.ensure([], () => r(require('@/views/productMaster/product')), 'product'),
        meta: { title: '製品マスター管理', icon: 'form', requireAuth: true }
      }
    ]
  },
  {
    path: '/pf/processFormat',
    component: Layout,
    children: [
      {
        path: 'process',
        name: '/processFormat/process',
        component: r => require.ensure([], () => r(require('@/views/processFormat/process')), 'process'),
        meta: { title: 'プロセスフォーマット管理', icon: 'form', requireAuth: true }
      }
    ]
  },
  {
    path: '/pf/ideaRule',
    component: Layout,
    children: [
      {
        path: 'basicRule',
        name: '/ideaRule/basicRule',
        component: r => require.ensure([], () => r(require('@/views/ideaRule/basicRule')), 'basicRule'),
        meta: { title: '原単位紐づけルール管理', icon: 'form', requireAuth: true }
      }
    ]
  },
  {
    path: '/pf/compare',
    component: Layout,
    children: [
      {
        path: 'abstract',
        name: '/compare/abstract',
        component: r => require.ensure([], () => r(require('@/views/compare/abstract')), 'abstract'),
        meta: { title: '排出量比較：概要', icon: 'form', requireAuth: true }
      },
      {
        path: 'explicit',
        name: '/compare/explicit',
        component: r => require.ensure([], () => r(require('@/views/compare/explicit')), 'explicit'),
        meta: { title: '排出量比較：詳細', icon: 'form', requireAuth: true }
      }
    ]
  },

  // 404 page must be placed at the end !!!
  { path: '*', redirect: '/404', hidden: true }
]

const createRouter = () => new Router({
  mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

const router = createRouter()

router.beforeEach((to, from, next) => {
  const user = store.state.user
  if (to.path === '/') {
    if (window.location.href.indexOf('testing') == -1) {
      window.location.href = 'https://lp.lcaplus-pf.com/'
    } else {
      window.location.href = 'http://lp-lcaplus-pf-com.sandbox.hs-sites.com/'
    }
  }
  if (to.meta.requireAuth) {
    if (user) {
      next()
    } else {
      next({
        path: '/'
      })
    }
  } else {
    next()
  }
})

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
